import * as React from 'react';
import { useMemo, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Collapse, TextField } from '@material-ui/core';
import { ConnectSystemError } from 'components/onboarding/ConnectSystemError';
import {
  Button,
  Card,
  Content,
  Expandable,
  Flex,
  Link,
  Modal,
  Spinner,
  Text,
} from 'component-library';
import { ImportType, PayrollSystem } from 'lib/interfaces';
import { FINCH_PAYROLL_SYSTEMS } from 'lib/constants';
import { Autocomplete } from '@material-ui/lab';
import {
  ConnectFinch,
  ConnectPayrollDisplayProps,
} from 'components/ConnectFinch';
import CardContent from '@material-ui/core/CardContent/CardContent';
import {
  ConnectCard,
  ConnectCardHeader,
  PayrollInvite,
} from 'pages/dashboard/integrations';
import { sortBy } from 'lodash';
import { RipplingConnect } from './rippling/RipplingConnect';
import { useCompany } from 'stores/useStores';
import { observer } from 'mobx-react';
import { PayrollFileUploader } from './components/PayrollFileUploader';

const useStyles = makeStyles(({ darkBlue }: Theme) => ({
  cardContent: {
    padding: '0 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  connectContent: {
    padding: '24px 0 0',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  noAccess: {
    color: darkBlue,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  manualPayrollContent: {
    paddingTop: '20px',
    width: '100%',
  },
  payrollDropdown: {
    maxWidth: '370px',
    width: '100%',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0 15px',
  },
  marginBottom: {
    margin: '0 0 15px',
  },
  expandable: {
    width: '100%',
    maxWidth: '640px',
  },
}));

interface ConnectPayrollProps {
  onPayrollConnected: () => Promise<void>;
  required: boolean;
  connectManualPayroll?: () => void;
  moveToNextStep?: (option?: PayrollSystem) => Promise<void>;
  storeDropdownSelection?: boolean;
  useCardComponent?: boolean;
  showDoThisLaterModal?: boolean;
  title?: string;
  importType: ImportType;
  taxYear?: number; // only need year for Qualification and EC
  onDoThisLater?: (bool: boolean) => void;
  doThisLaterModalTitle?: string;
  doThisLaterModalContent?: string;
  noMargin?: boolean;
  subtitle?: string;
  onAfterManual?: () => void;
  skipThisStep?: boolean;
}

const RIPPLING = 'rippling';
const ONPAY = 'onpay';

export const ConnectPayrollCard = observer(
  ({
    onPayrollConnected,
    connectManualPayroll,
    moveToNextStep,
    storeDropdownSelection,
    required,
    useCardComponent,
    showDoThisLaterModal,
    title,
    importType,
    taxYear,
    onDoThisLater,
    doThisLaterModalTitle,
    doThisLaterModalContent,
    noMargin,
    subtitle = `If you don't have access to this or prefer to do it later, you can skip this for now.`,
    onAfterManual,
    skipThisStep,
  }: ConnectPayrollProps) => {
    const classes = useStyles();
    const [doThisLaterModal, setDoThisLaterModal] = useState<boolean>(false);
    const { company } = useCompany();

    const includeManualOptions =
      !!connectManualPayroll ||
      importType === ImportType.ExpenseClassification ||
      importType === ImportType.UnifiedAssessment;
    const manualPayrollOptions = useMemo(
      () => [
        {
          id: 'manual',
          displayName:
            importType === ImportType.ExpenseClassification ||
            importType === ImportType.UnifiedAssessment
              ? 'Enter data manually'
              : 'Add manually',
        },
        { id: 'other', displayName: 'Other' },
      ],
      [],
    );

    /**
     * 02/11/25: Excluding Rippling until we negotiate a new contract with them.
     * For now, we will have Rippling customers enter payroll info manually.
     */
    // const rippling = { id: RIPPLING, displayName: 'Rippling', manual: false };
    const sortedPayrollSystems = sortBy([...FINCH_PAYROLL_SYSTEMS], 'id');

    // Remove manual payroll systems from unified qual / EC page.
    const filteredPayrollSystems =
      importType === 'expense-classification'
        ? sortedPayrollSystems.filter(function (item) {
            return item?.manual === false;
          })
        : sortedPayrollSystems;

    const payrollOptions: PayrollSystem[] = [
      ...filteredPayrollSystems,
      ...(includeManualOptions ? manualPayrollOptions : []),
    ];

    /* Set payrollSystem to previously selected if one exists*/
    const [payrollSystem, setPayrollSystem] = useState<PayrollSystem | null>(
      payrollOptions.find(
        (option) => option.id === company.misc?.payrollSystem,
      ) || null,
    );
    const [useManualPayroll, setUseManualPayroll] = useState<boolean>(false);
    const [useFinchPayroll, setUseFinchPayroll] = useState<boolean>(false);
    const [useRipplingPayroll, setUseRipplingPayroll] =
      useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showManualModal, setShowManualModal] = useState<boolean>(false);
    const [useOnPayPayroll, setUseOnPayPayroll] = useState<boolean>(false);

    React.useEffect(() => {
      setUseManualPayroll(
        !!payrollSystem &&
          manualPayrollOptions.map((p) => p.id).includes(payrollSystem.id),
      );
      setUseFinchPayroll(
        !!payrollSystem &&
          !manualPayrollOptions.map((p) => p.id).includes(payrollSystem.id) &&
          payrollSystem.id !== RIPPLING &&
          payrollSystem.id !== ONPAY,
      );
      setUseRipplingPayroll(payrollSystem?.id === RIPPLING);
      setUseOnPayPayroll(payrollSystem?.id === ONPAY);
    }, [payrollSystem, manualPayrollOptions]);

    const submitManualPayroll = async () => {
      setLoading(true);

      if (importType === ImportType.ConnectionsPage) {
        if (!connectManualPayroll) {
          return;
        }
        setLoading(true);
        connectManualPayroll();
      }

      if (moveToNextStep) {
        moveToNextStep(payrollSystem ?? undefined);

        if (
          (importType === ImportType.UnifiedAssessment ||
            importType === ImportType.ExpenseClassification) &&
          onAfterManual
        ) {
          onAfterManual();
        }
      } else {
        setPayrollSystem(null);
      }

      setLoading(false);
    };
    /* Display the previous year when it's Jan, Feb, March; else display the current year */
    const currentDate = new Date();
    const payrollYear =
      currentDate.getMonth() <= 2
        ? currentDate.getFullYear() - 1
        : currentDate.getFullYear();

    const ConnectFinchDisplay = ({
      openFinch,
      loading,
      finchError,
    }: ConnectPayrollDisplayProps) => {
      const [showInviteLink, setShowInviteLink] = useState<boolean>(false);
      return (
        <>
          <div className={classes.connectContent} data-testid='finch-connect'>
            <Button
              label={`Connect`}
              onClick={openFinch}
              loading={loading}
              dataTestId={'connect-payroll-card-connect-button'}
            />
            {!storeDropdownSelection && (
              <Link
                className={classes.noAccess}
                onClick={() => setShowInviteLink(true)}
              >
                {"I don't have access to this"}
              </Link>
            )}
            {storeDropdownSelection &&
              payrollSystem !== null &&
              moveToNextStep && (
                <Button
                  label='Connect later'
                  variant={'outlined'}
                  data-testid='payroll_connect_later_finch'
                  onClick={() =>
                    showDoThisLaterModal
                      ? setDoThisLaterModal(true)
                      : moveToNextStep(payrollSystem)
                  }
                />
              )}
          </div>

          {finchError && <ConnectSystemError systemType='payroll' />}
          {showInviteLink && <PayrollInvite />}
        </>
      );
    };

    const connectCardContent = (
      <CardContent className={classes.cardContent}>
        <div className={classes.payrollDropdown}>
          <Autocomplete
            data-testid='autocomplete'
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Payroll Providers'
                className={'payrollProviderList'}
              />
            )}
            getOptionSelected={(option, value) => option.id === value.id}
            options={payrollOptions}
            value={payrollSystem as unknown as PayrollSystem}
            getOptionLabel={(option) => option.displayName}
            onChange={(event, option) => {
              if (option) {
                if (
                  (importType === ImportType.ExpenseClassification ||
                    importType === ImportType.UnifiedAssessment) &&
                  option.id === 'manual'
                ) {
                  setShowManualModal(true);
                } else {
                  setPayrollSystem(option);
                }
              }
            }}
          />
        </div>
        <Modal showModal={showManualModal} maxWidth={536}>
          <Flex direction='column' gap={16} padding={24}>
            <Text variant='medium' size={18}>
              Thank you!
            </Text>
            <Text>
              You will be able to enter all relevant information without
              connecting a payroll account.
            </Text>
            <Text>
              If you need assistance along the way, please reach out to{' '}
              <a href='mailto:support@mainstreet.com'>support@mainstreet.com</a>
              .
            </Text>
            <Flex gap={16} justifyContent='flex-start'>
              <Button label='Ok' onClick={onAfterManual!} />
            </Flex>
          </Flex>
        </Modal>
        <Expandable
          expand={useManualPayroll || useOnPayPayroll}
          className={classes.expandable}
        >
          {loading ? (
            <Flex justifyContent='center' padding={24}>
              <Spinner size='small' color='emerald' />
            </Flex>
          ) : (
            <PayrollFileUploader
              payrollYear={payrollYear}
              submitManualPayroll={submitManualPayroll}
              payrollSystem={payrollSystem}
              setDoThisLaterModal={setDoThisLaterModal}
              storeDropdownSelection={storeDropdownSelection}
              showDoThisLaterModal={showDoThisLaterModal}
              moveToNextStep={moveToNextStep}
              skipThisStep={skipThisStep!}
              importType={importType}
              partner={payrollSystem?.id === ONPAY ? 'onpay' : undefined}
              taxYear={taxYear}
            />
          )}
        </Expandable>
        <Collapse in={useFinchPayroll}>
          <ConnectFinch
            onPayrollConnected={onPayrollConnected}
            connectPayrollDisplay={ConnectFinchDisplay}
            payrollSystem={payrollSystem ? payrollSystem.id : undefined}
            importType={importType}
            taxYear={taxYear}
          />
        </Collapse>
        <Collapse in={useRipplingPayroll}>
          <RipplingConnect
            moveToNextStep={moveToNextStep}
            importType={importType}
            taxYear={taxYear}
          />
        </Collapse>
      </CardContent>
    );

    const header = (
      <ConnectCardHeader
        title={title || `Connect payroll software`}
        subtitle={
          storeDropdownSelection
            ? importType === ImportType.ExpenseClassification
              ? `We recommend connecting now if you can - it can take some time to import the relevant data!`
              : subtitle
            : undefined
        }
        actionRequired={required}
      />
    );

    const showModal = (
      <Modal
        showModal={doThisLaterModal}
        closeToggle={() => setDoThisLaterModal(false)}
        maxWidth={480}
      >
        <>
          <Content paddingTopBottom={24} paddingLeftRight={24}>
            <Text
              variant='medium'
              size={18}
              text={
                doThisLaterModalTitle
                  ? doThisLaterModalTitle
                  : 'We highly recommend connecting now.'
              }
              paddingBottom={16}
            />
            <Text
              text={
                doThisLaterModalContent
                  ? doThisLaterModalContent
                  : "If you do not connect to your payroll account, you may be required to enter your employees' wages, benefits, and job details manually."
              }
            />
          </Content>
          <Content gap={16} flex paddingLeftRight={24}>
            <Button
              label='Connect payroll'
              onClick={() => setDoThisLaterModal(false)}
            />
            <Button
              variant='outlined'
              label='Skip anyway'
              onClick={() => {
                if (moveToNextStep && payrollSystem !== null) {
                  moveToNextStep(payrollSystem);
                }
                if (onDoThisLater) {
                  onDoThisLater(true);
                }
                setDoThisLaterModal(false);
              }}
            />
          </Content>
        </>
      </Modal>
    );

    return useCardComponent ? (
      <Card noMargin={noMargin}>
        <Content>{header}</Content>
        <Content paddingTop={0} paddingLeftRight={0} paddingBottom={8}>
          {connectCardContent}
        </Content>
        {showDoThisLaterModal && moveToNextStep && showModal}
      </Card>
    ) : (
      <ConnectCard header={header} mainContent={connectCardContent} noPadding />
    );
  },
);
